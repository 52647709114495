<template>
  <div class="background-container">
    <v-container>
      <v-card>
      <v-breadcrumbs :items="[
        { text: 'Solutions', exact: true, to: { name: 'solutions' } },
        { text: 'Race Results', disabled: true },
      ]" />
      <div :class="` ${$vuetify.breakpoint.xs?'':''}`">
        <h1>Race Results</h1>
        <p>
          Publish in-person race results to our fully customizable app and web platform. Leaderboards are customizable and results can be liked and shared. Users can upload their selfies and post comments.
        </p>
        <p>

        </p>
      </div>

      <div id="page-customers" class="mt-0 white--text">
        <div class="container pb-0">
          <v-row>
            <v-col class="pb-4">
              <h1 class="headline">Trusted by world-class event organizers</h1>
              <p>
                The Sodisp Platform is used by world-renowned event organizers and managements organizations all across the world. Some of our key partners are:
              </p>
              <SectionCustomers showcase/>

            </v-col>
          </v-row>
        </div>
      </div>

    <div class="pb-6 pb-md-12">
      <v-row >
        <v-col cols="12" sm="6">
          <v-card :color="$helpers.getGradientColor(0)">
            <v-card-text class="pb-0"><v-icon size="100">fadl fa fa-mobile</v-icon></v-card-text>
            <v-card-title class="headline">Free App</v-card-title>
            <v-card-text>
              Use our free app to publish event results. Our app is fully customizable to match your brand identity. 
              Add sponsors, change colors, and customize the artwork.
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" sm="6">
          <v-card :color="$helpers.getGradientColor(2)">
            <v-card-text class="pb-0"><v-icon size="100">fadl fa fa-fill-drip</v-icon></v-card-text>
            <v-card-title class="headline">Customizable Leaderboards</v-card-title>
            <v-card-text>
              Result pages can be fully customized to show the information relevant to your event. 
              Customize colors, add sponsors, customize content, and many more options available.
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" sm="6">
          <v-card :color="$helpers.getGradientColor(1)">
            <v-card-text class="pb-0"><v-icon size="100">fadl fa fa-user-headset</v-icon></v-card-text>
            <v-card-title class="headline">Full-Service support available</v-card-title>
            <v-card-text>
              Choose between our self-service or full-service packages to select the best option for your organization. 
              No upfront commitment or minimum payment required! Only pay for additional registrations of virtual edition.
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" sm="6">
          <v-card :color="$helpers.getGradientColor(3)">
            <v-card-text class="pb-0"><v-icon size="100">fadl fa fa-share-all</v-icon></v-card-text>
            <v-card-title class="headline">Generate Shareable Content</v-card-title>
            <v-card-text>
              Our fully customizable leaderboards not only look beautiful but also invite participants to share their results. 
              Each result can be liked, commented on and participants can upload their selfies to show on their personal result.
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" class="text-muted text-center">
          <div><v-icon class="my-6" color="grey">fal fa-chevron-double-down</v-icon></div>
          Scroll down for more features.
        </v-col>
      </v-row>
    </div>

    <div class="secondary white--text py-6 py-md-12">
      <div class="container text-center">
        <v-row>
          <v-col cols="12" md="12">
            <h2 class="subtitle">Publish in-person race results easily</h2>
            <h1 class="subtitle">Use sodisp to power connected and engaging events!</h1>
            <p class="mb-0 mt-4">
              <v-btn class="mb-4 mr-4" color="white" :to="{name: 'eventmanagerCreate'}">Create your event</v-btn>
              <v-btn class="mb-4" color="white" outlined href="/request-demo">Request Demo</v-btn>
            </p>
          </v-col>
        </v-row>
      </div>
    </div>

    <div :class="`mt-10 text-center ${$vuetify.breakpoint.xs?'':'indent'}`">
      <h1>Discover our key features</h1>
      <p>
      </p>
    </div>
    <v-container class="features">
      <v-row>
        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-watch</v-icon>
          <span class="plus">+</span>
          <v-icon x-large color="primary">fab fa-strava</v-icon>
          <h4>Auto-sync with Garmin, Strava, Fitbit, Polar and more</h4>
          <p>
            Activities tracked with Garmin, Polar, Suunto, Fitbit or COROS devices and the Strava app are automatically synced and added to results making it extremely easy for your participants to make every km/mile count!
          </p>

          <p>
            Next to our native integrations we also support manual entry or upload of any other gpx file.  
          </p>
        </v-col>

        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-stopwatch</v-icon>
          <span class="plus">+</span>
          <v-icon x-large color="primary">fadl fa fa-ruler</v-icon>
          <span class="plus">+</span>
          <v-icon x-large color="primary">fadl fa fa-mountain</v-icon>
          <h4>Best result, total distance, total elevation and more</h4>
          <p>
            We support all popular challenge modes: best result, total distance or total elevation. Even better: you can combine them in the same virtual challenge!
          </p>
          <p> 
            Whether your virtual event/challenge runs for a day, a week or even multiple months, whether it is a single sport or a multi-sport event, you can host it on sodisp.
          </p>
        </v-col>
        

        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-swimmer fa-flip-horizontal</v-icon>
          <span class="plus">+</span>
          <v-icon x-large color="primary">fadl fa fa-bicycle</v-icon>
          <span class="plus">+</span>
          <v-icon x-large color="primary">fadl fa fa-running</v-icon>
          <h4>All sports and combinations</h4>
          <p>
            We support all popular sports and you can combine them all in the same challenge.
          </p>
          <p> 
            Use our platform to host virtual triathlons, multi-sport events or any combinations of different activity types.
          </p>
        </v-col>
        
        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-map</v-icon>
          <h4>Progress on map</h4>
          <p>
            Make your challenge more fun by mapping the progress on a real map. Simply upload your virtual course map and we'll map the progress on the map.
          </p>
          <p>
            We show all participants on the same map and have a powerful search option to find your own or team members' results easily!
          </p>
        </v-col>
        
        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-shield</v-icon>
          <h4>Badges</h4>
          <p>
            Create your own intermediate goals based on distance or elevation completed and award badges to keep your participants motivated to make it to the (virtual) finish line! Use our consecutive challenge to motivate your participants to keep going and our location based badges for virtual treasure hunts or embed local content into your challenge.
          </p>
          <p>
            Easily customize the layout or even use custom images to make the badges unique to your challenge. You can optionally enable and customize email messages to be sent whenever a badge is awarded and view overall statistics of each badge on your race result page.
          </p>
        </v-col>
        
        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-bullseye</v-icon>
          <h4>Collective progress &amp; Participation Mode</h4>
          <p>
            We're into this together, so let's work together in reaching our common goals!
            Easily set a collective target and everyone's effort will count toward reaching this collective goal!
          </p>
          <p> 
            Enable our participation mode for non-competitive challenges to change the leaderboard into a ticker board where the latest activity 
            is shown at the top to give everyone their moment in the spotlights, regardless of distance, pace, or speed!
          </p>
        </v-col>
        
        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-user</v-icon>
          <span class="plus">+</span>
          <v-icon x-large color="primary">fadl fa fa-users</v-icon>
          <h4>Individual or team races</h4>
          <p>
            Create both individual or team challenges, or combinations of both! With the team functionality any group can work together to reach their common goals and compete with the other registered teams for first place!
          </p>
        </v-col>
        
        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-list</v-icon>
          <h4>Connect with your official race registration data</h4>
          <p>
            Easily load your registration data to create official results and allow only registered participants to join your virtual event.
          </p>
        </v-col>

        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-paint-roller</v-icon>
          <h4>Customize design</h4>
          <p> 
            Make it yours by customizing the design of your virtual event/challenge. Easily upload your logo and artwork to make your virtual event look beautiful.
          </p>
        </v-col>

        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-tools</v-icon>
          <h4>Powerful Management Tools</h4>
          <p> 
            Use our easy to use, yet powerful management tools to manage your virtual challenge and results. All features can be configured using our self-service tools. Need help? No worries, we have extensive knowledge base articles available or can help you with an onboarding trainings session.
          </p>
        </v-col>

        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-cogs</v-icon>
          <h4>API integrations</h4>
          <p> 
            Use our REST APIs for custom integration with your systems and services. We provide APIs for various integration scenarios such as automated result or registration data sync. We have Excel import/export options available to help you get started without development work needed.
          </p>
        </v-col>

        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-tags</v-icon>
          <h4>Get started for free</h4>
          <p> 
            Get started with sodisp for free. <!-- See our <router-link to="/pricing">simple and fair pricing</router-link> for details.  --><router-link to="/contact">Contact us</router-link> to discuss how sodisp can help your virtual event or challenge.
          </p>
        </v-col>
      </v-row>
    </v-container>

    <div class="green white--text py-6 py-md-12">
      <div class="container text-center">
        <v-row>
          <v-col cols="12" md="12">
            <h1 class="subtitle">Get started today!</h1>
            <p class="mb-0 mt-4">
              <v-btn class="mb-4 mr-4" color="white" :to="{name: 'eventmanagerCreate'}">Create your event</v-btn>
              <v-btn class="mb-4" color="white" outlined href="/contact">Contact Us</v-btn>
            </p>
          </v-col>
        </v-row>
      </div>
    </div>
      </v-card>
    </v-container>
  </div>
</template>


<script>
import SectionIntegrations from "@/components/SectionIntegrations.vue";
import SectionCustomers from "@/components/SectionCustomers.vue";
import siteData from '@/data/site.json'
import providers from '@/util/oauthProviders.js'

export default {
  name: "Contact",
  components: {
    SectionIntegrations,
    SectionCustomers,
  },
  props: {
  },
  data: function() {
    return {
      siteData: siteData,
      providers: providers,
    };
  },
  created() {
  },
  mounted() {
  },
  methods: {
  },
  watch: {
  }
};
</script>

<style lang="scss" scoped>
  .indent { margin: 0 20%; }
  .features {
    i { background-color: #F4652422; border-radius: 50%; padding: 20px; margin-top: 20px; }
    .plus {
      margin: 0 20px; line-height: 40px;
      color: #F46524;
    }
    h4 { margin-top: 8px; }
  }

  .background-container { background-image: url(https://sodisp.imgix.net/content/running-event.jpg); }
</style>